<script>
import axios from 'axios'
import Vue from 'vue'
import './assets/tailwind.css'

import {
    BaseButton,
    IconCross,
} from 'aca-design'

import Handlebars from '@/Handlebars.js'

import BaseLoader from '@/components/BaseLoader.vue'
import CourseDetails from '@/components/CourseDetails.vue'
import BasketScreen from '@/views/BasketScreen.vue'
import HomeScreen from '@/views/HomeScreen.vue'

window.Vue = Vue

Vue.filters = Vue.prototype.$filters = {
    plural: (value, count) => {
        if (count > 1) {
            return value + 's'
        }

        return value
    }
}

const wait = (duration) => {
    return new Promise(r => setTimeout(r, duration))
}

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

const uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item)

                map.has(key) || map.set(key, item)

                return map
            }, new Map())
            .values(),
    ]
}

function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) {
            el.values.push(x);
        } else {
            rv.push({ key: v, values: [x] });
        }
        return rv;
    }, []);
}

function chunkArray(array, size) {
    const results = []

    while (array.length) {
        results.push(array.splice(0, size))
    }

    return results
}

function addOrRemove(array, value) {
    var index = array.indexOf(value)

    if (index === -1) {
        array.push(value)
    } else {
        array.splice(index, 1)
    }
}

function nl2br(str, replaceMode) {
    var breakTag = '<br>'
    var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2'
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

export default {
    name: 'App',

    components: {
        BasketScreen,
        HomeScreen,
        BaseLoader,
        BaseButton,
        IconCross,
        CourseDetails,
    },

    props: {
        landingName: {
            type: String,
            default() {
                const urlParams = new URLSearchParams(window.location.search)
                const landingName = urlParams.get('forceLandingName')
                return landingName || 'planning-stages'
            }
        },

        prefilledModel: {
            type: String,
            default: '{}'
        },

        prefilledFilters: {
            type: String,
            default: '{}'
            // default: '{"CodePostal":"49400","MatiereIds":"27"}'
        },

        backgroundRGB: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            currentScreen: 'home',
            coursesLoading: false,
            landingLoading: false,
            contactFormModel: {},
            modalOpen: false,
            modalProps: {},
            selectedCourses: [],
            matchingCourses: [],
            displayedCourses: [],
            matchingCoursesLength: undefined,
            landing: null,
            allCourses: [],
            allDetails:  [],
            locationFilter: ['c', 'l'],
            subjectsFilter: [],
            levelFilter: null,
            planningFilter: 'Stages de vacances', // 'Stages de vacances', 'Soutien Hebdo'
            periodFilter: null,
            zipCodeFilter: '',
            courseElementInViewport: [],
            checkedVariants: [],
            levelsForSubject: {},
            subjectsForLevel: {},
            clickedFindCourses: false,
            hasAnyPrefilledFilter: false,
        }
    },

    computed: {
        isStage() {
            return this.planningFilter === 'Stages de vacances'
        },
        CSSVars() {
            if (this.backgroundRGB) {
                return { '--background-rgb': this.backgroundRGB }
            } else {
                return {}
            }
        },

        model() {
            return JSON.parse(this.prefilledModel)
        },

        hasAnyFilter() {
            const filters = {
                ...this.filters
            }

            delete filters.Planning
            return !!Object.values(filters).filter(Boolean).length
        },

        filters() {
            const typeStage = this.locationFilter.length === 1 ? this.locationFilter[0] : null
            return {
                Planning: this.planningFilter,
                TypeStage: typeStage,
                NiveauId: this.levelFilter?.value,
                MatiereIds: this.subjectsFilter.map(({ value }) => value).join(','),
                CodePostal: this.zipCodeFilter?.cpCode ? this.zipCodeFilter.cpCode : null,
                Vacances: this.periodFilter?.value,
            }
        },

        allLevels() {
            const allLevels = this.allCourses.map((course) => {
                return {
                    value: course.niveauId,
                    name: course.niveauLib,
                }
            })
            return uniqBy(allLevels, 'value')
        },

        allSubjects() {
            const allSubjects = this.allCourses.map((course) => {
                return {
                    value: course.matiereId,
                    name: course.matiereLib,
                }
            })
            return uniqBy(allSubjects, 'name')
        },

        allPeriods() {
            const allPeriods = this.allCourses.map((course) => {
                return {
                    value: course.vacances,
                    name: course.vacances,
                }
            })

            return uniqBy(allPeriods, 'value')

            // const filter = this.landing?.filters.find(({type}) => type === 'period')
            // const allPeriods = Object.keys(filter.values)
            //     .map((key) => ({ value: key, name: filter.values[key] }))

            // return allPeriods
        },

        filteredLevels() {
            if (!this.subjectsFilter?.length) {
                return this.allLevels
            }

            let filteredCourses = this.allCourses

            // if (this.periodFilter) {
            //     filteredCourses = filteredCourses.filter((course) => {
            //             return this.periodFilter.value === course.vacances
            //         })
            // }

            if (this.subjectsFilter?.length) {
                filteredCourses = filteredCourses
                    .filter((course) => {
                        return !!this.subjectsFilter.find(({ value }) => {
                            return course.matiereId === value
                        })
                    })
            }

            const filteredLevels = filteredCourses.map((course) => {
                return {
                    value: course.niveauId,
                    name: course.niveauLib,
                }
            })

            return uniqBy(filteredLevels, 'value')
        },

        filteredSubjects() {
            if (!this.levelFilter) {
                return this.allSubjects
            }

            let filteredCourses = this.allCourses

            // if (this.periodFilter) {
            //     filteredCourses = filteredCourses.filter((course) => {
            //             return this.periodFilter.value === course.vacances
            //         })
            // }

            if (this.levelFilter) {
                filteredCourses = filteredCourses.filter((course) => {
                        return parseInt(this.levelFilter.value) === parseInt(course.niveauId)
                    })
            }

            const filteredSubjects = filteredCourses.map((course) => {
                return {
                    value: course.matiereId,
                    name: course.matiereLib,
                }
            })

            return uniqBy(filteredSubjects, 'name')
        },

        filteredPeriods() {
            if (!this.levelFilter && !this.subjectsFilter?.length) {
                return this.allPeriods
            }

            let filteredCourses = this.allCourses

            if (this.subjectsFilter?.length) {
                filteredCourses = filteredCourses
                    .filter((course) => {
                        return !!this.subjectsFilter.find(({ value }) => {
                            return course.matiereId === value
                        })
                    })
            }

            if (this.levelFilter) {
                filteredCourses = filteredCourses.filter((course) => {
                        return parseInt(this.levelFilter.value) === parseInt(course.niveauId)
                    })
            }

            const filteredPeriods = filteredCourses.map((course) => {
                return this.allPeriods.find(({ value }) => value === course.vacances)
            }).filter(Boolean)

            return uniqBy(filteredPeriods, 'value')
        },

        matchingLevels() {
            const matchingLevels = this.matchingCourses.map((course) => {
                return {
                    value: course.niveauId,
                    name: course.niveauLib,
                }
            })
            return uniqBy(matchingLevels, 'value')
        },

        matchingSubjects() {
            const matchingSubjects = this.matchingCourses.map((course) => {
                return {
                    value: course.matiereId,
                    name: course.matiereLib,
                }
            })

            return uniqBy(matchingSubjects, 'name')
        },

        selectedCourseIds() {
            return this.selectedCourses.map(({ course }) => course.id)
        },

        groupedDisplayedCourses() {
            const grouped = this.groupCourses(this.displayedCourses)

            return grouped.map((group) => {
                group.sort((a, b) => {
                    const aDebut = a.heureDebut.endsWith('h') ? a.heureDebut + '00' : a.heureDebut
                    const bDebut = b.heureDebut.endsWith('h') ? b.heureDebut + '00' : b.heureDebut
                    return parseInt(aDebut.replace('h', '')) - parseInt(bDebut.replace('h', ''))
                })
                return group
            })
        },
    },

    watch: {
        currentScreen: {
            handler: function () {
                this.$emit('message', {
                    eventName: 'ACA_FORM_SCREEN_CHANGED',
                    payload: {
                        screen: this.currentScreen
                    }
                })
                this.$el?.scrollIntoView(true)
            },

            immediate: true
        },

        selectedCourses: {
            handler: function () {
                if (!this.selectedCourses.length && this.currentScreen === 'basket') {
                    this.currentScreen = 'home'
                }
            },

            immediate: true
        },

        filters: {
            handler: async function (a, b) {
                if (!this.clickedFindCourses && !this.hasAnyPrefilledFilter) {
                    return
                }
                if (JSON.stringify(a) === JSON.stringify(b)) {
                    return
                }
                this.coursesLoading = true
                const courses = await this.fetchCourses(this.filters)
                this.coursesLoading = false
                const uniqCourses = this.deduplicatedCourses(courses)
                this.matchingCoursesLength = this.groupCourses(uniqCourses).length
                this.matchingCourses = uniqCourses
                this.displayedCourses = this.matchingCourses.splice(0, 20)
            },
            deep: true,
            immediate: false
        },

        modalOpen: {
            handler: async function () {
                document.body.style.overflow = this.modalOpen ? 'hidden' : 'auto'
            },
            immediate: false
        },
    },

    beforeMount() {
        this.$emit('message', { eventName: 'ACA_FORM_BEFORE_MOUNT' })
    },

    async mounted() {
        this.$emit('message', { eventName: 'ACA_FORM_MOUNTED' })

        this.landingLoading = true
        this.landing = await this.fetchLanding()
        // this.landingLoading = false

        let prefilledFilters = {
            ...JSON.parse(this.landing.prefilled_filters || '{}'),
            ...JSON.parse(this.prefilledFilters || '{}')
        }

        const courses = await this.fetchCourses({
            Planning: prefilledFilters.Planning || 'Stages de vacances'
        })

        this.allCourses = this.deduplicatedCourses(courses)
        this.landingLoading = false

        if (prefilledFilters && Object.keys(prefilledFilters).length) {
            this.hasAnyPrefilledFilter = true
            try {
                if (prefilledFilters.TypeStage) {
                    this.locationFilter = prefilledFilters.TypeStage.split(',')
                }
                if (prefilledFilters.NiveauId) {
                    this.levelFilter = this.allLevels.find((level) => parseInt(level.value) === parseInt(prefilledFilters.NiveauId))
                }
                if (prefilledFilters.Planning) {
                    this.planningFilter = prefilledFilters.Planning
                }
                if (prefilledFilters.MatiereIds) {
                    this.subjectsFilter = prefilledFilters.MatiereIds.split(',').map((id) => {
                        return this.allSubjects.find((subject) => parseInt(subject.value) === parseInt(id))
                    }).filter(Boolean)
                }
                if (prefilledFilters.CodePostal) {
                    this.zipCodeFilter = this.model.ville ? prefilledFilters.CodePostal + ' - ' + this.model.ville : prefilledFilters.CodePostal
                }
                if (prefilledFilters.Vacances) {
                    this.periodFilter = {
                        name: prefilledFilters.Vacances,
                        value: prefilledFilters.Vacances,
                    }
                }
            } catch (e) {
                console.log('Error setting prefilled_filters', e)
            }
        } else {
            this.matchingCoursesLength = this.groupCourses(this.allCourses).length
            this.matchingCourses = this.allCourses
            this.displayedCourses = this.matchingCourses.splice(0, 20)
        }

        // this.allCourses.forEach((course) => {
        //     if (!this.levelsForSubject[course.matiereId]) {
        //         this.levelsForSubject[course.matiereId] = []
        //     }

        //     const levelIndex = this.levelsForSubject[course.matiereId]
        //         .indexOf(course.niveauId)

        //     if (levelIndex < 0) {
        //         this.levelsForSubject[course.matiereId].push(course.niveauId)
        //     }

        //     if (!this.subjectsForLevel[course.niveauId]) {
        //         this.subjectsForLevel[course.niveauId] = []
        //     }

        //     const subjectIndex = this.subjectsForLevel[course.niveauId]
        //         .indexOf(course.matiereId)

        //     if (subjectIndex < 0) {
        //         this.subjectsForLevel[course.niveauId].push(course.matiereId)
        //     }
        // })

        this.$emit('message', { eventName: 'ACA_FORM_LOADED' })

        const details = await this.fetchDetails()
        this.allDetails = details.map((detail) => {
            return {
                ...detail,
                Objectifs: nl2br(detail.Objectifs),
                produit_ids: (detail.produit_id || '').split(';').map((id) => parseInt(id))
            }
        })
    },

    methods: {
        deduplicatedCourses(courses) {
            let dedupCourses = uniqBy(courses, 'id')
            dedupCourses = uniqBy(dedupCourses, 'dedupKey')

            // We use dedupKey instead for better perfs
            // const seen = {}

            // const dedupCourses = cleanCourses.filter((course, index, self) => {
            //     const foundDuplicate = self.find((c) => {
            //         if (c.id === course.id) {
            //             return false
            //         }

            //         if (c.agenceId !== course.agenceId) {
            //             return false
            //         }

            //         if (c.niveauId !== course.niveauId) {
            //             return false
            //         }

            //         if (c.dateDebut !== course.dateDebut) {
            //             return false
            //         }

            //         if (c.heureDebut !== course.heureDebut) {
            //             return false
            //         }

            //         if (seen[course.id]) {
            //             return false
            //         }

            //         // Some courses can be identical except coursId (and lieu), if it passes every other condition we consider them duplicate
            //         if (c.coursId !== course.coursId ) {
            //             seen[c.id] = true
            //             return true
            //         }

            //         return false
            //     })

            //     return !foundDuplicate
            // })

            return [...dedupCourses]
        },

        groupCourses(courses) {
            return groupBy(courses, (course) => {
                return `${course.produitId}-${course.agenceId}-${course.niveauId}-${course.matiereId}-${course.dateDebut}`
            }).map((group) => group.values)
        },

        async chunkInsert(source, dest) {
            await asyncForEach(chunkArray([...source], 200), async (chunk) => {
                dest.push(...chunk)
                await wait(100)
            })
        },

        async fetchLanding(){
            const landingEndpoint = `${process.env.VUE_APP_DIRECTUS_API_URL}/items/composants_stages?filter[name][_eq]=${this.landingName}&fields=*,contact_form.name`

            const resp = await axios.get(landingEndpoint)
            return resp.data.data[0]
        },

        async handleFindCourses() {
            if (!this.hasAnyFilter) { return }
            this.clickedFindCourses = true

            this.coursesLoading = true
            const courses = await this.fetchCourses(this.filters)
            this.coursesLoading = false
            const uniqCourses = this.deduplicatedCourses(courses)
            this.matchingCoursesLength = this.groupCourses(uniqCourses).length
            this.matchingCourses = uniqCourses
            this.displayedCourses = this.matchingCourses.splice(0, 20)
        },

        async fetchCourses(filters){
            const options = {
                method: 'GET',
                url: `${process.env.VUE_APP_CONTACT_API_URL}/reservation/chargerStage`,
                params: {
                    Planning: 'Stages de vacances',
                    NbMois: '5',
                    ...filters
                },
                headers: {
                  'Content-Type': 'application/json'
                }
            }
            const { data } = await axios.request(options)

            return data.map((course) => {
                const newCourse = {
                    ...course,
                }

                if (course.heureDebut.includes('|')) {
                    const [jourDebut, heureDebut] = course.heureDebut.split('|')
                    newCourse.jourDebut = jourDebut
                    newCourse.heureDebut = heureDebut
                }

                newCourse.id = `${course.coursId}-${course.agenceId}-${course.niveauId}-${course.dateDebut}`
                // Some courses can be identical except coursId (and lieu), if it passes every other condition we consider them duplicate
                newCourse.dedupKey = `${course.produitId}-${course.agenceId}-${course.niveauId}-${course.dateDebut}-${course.heureDebut}`,
                newCourse.produitDesc = course.produitDesc || course.produitdesc

                return newCourse
            })
        },

        async fetchDetails(){
            const options = {
                method: 'GET',
                url: `${process.env.VUE_APP_CONTACT_API_URL}/stages/sheets/read`,
                params: {
                    title: 'Stages',
                },
                headers: {
                  'Content-Type': 'application/json'
                }
            }
            const { data } = await axios.request(options)

            return data.rows
        },

        getCourseById(id) {
            return this.allCourses.find((course) => {
                return course.id === id
            })
        },

        getDetailById(id) {
            return this.allDetails.find((detail) => {
                return detail.produit_ids.includes(parseInt(id))
            })
        },

        handleLoadMore() {
            const newCourses = this.matchingCourses.slice(this.displayedCourses.length, this.displayedCourses.length + 40)
            this.displayedCourses.push(...newCourses)
        },

        handleZipcodeFilterChange($event) {
            this.zipCodeFilter = $event
        },

        handleLevelChange($event) {
            this.levelFilter = $event
        },

        handlePlanningChange($event) {
            this.planningFilter = $event
        },

        handleSubjectChange($event) {
            this.subjectsFilter = $event
        },

        handlePeriodChange($event) {
            this.periodFilter = $event
        },

        handleSelectCourseClick(group, course) {
            this.selectedCourses.push({
                group: [...group],
                course: { ...course }
            })
            this.currentScreen = 'basket'
        },

        handleRemoveCourseClick(groupId) {
            const index = this.selectedCourses.findIndex((selectedCourse) => {
                return selectedCourse.group[0].id === groupId
            })
            this.selectedCourses.splice(index, 1)
        },

        handleSelectedVariantChange(course, group) {
            const index = this.selectedCourses.findIndex((selectedCourse) => {
                return selectedCourse.group[0].id === group[0].id
            })
            this.$set(this.selectedCourses, index, {
                group: [...group],
                course: { ...course }
            })
        },

        handleCourseProgramClick(group, course) {
            this.modalOpen = true
            const detail = this.getDetailById(course.produitId)

            this.modalProps = {
                group,
                course,
                detail
            }
        },

        handleBookCourse(group, course) {
            this.modalOpen = false
            this.currentScreen = 'basket'
            this.selectedCourses.push({
                group: [...group],
                course: { ...course }
            })
        },

        handleLocationFilterChange(key) {
            addOrRemove(this.locationFilter, key)
        },

        handleLocationFilterAdd(key) {
            if (this.locationFilter.indexOf(key) < 0) {
                this.locationFilter.push(key)
            }
        },

        handleSuccess() {
            this.currentScreen = 'success'
            this.selectedCourses = []
        },

        handleModelChanged(model) {
            this.contactFormModel = model
        },

        readableLevel(level) {
            if (level === '1ère') {
                return '1re'
            }
            if (level === '2nd') {
                return '2de'
            }
            if (level === '3ème') {
                return '3e'
            }
            if (level === '4ème') {
                return '4e'
            }
            if (level === '5ème') {
                return '5e'
            }
            if (level === '6ème') {
                return '6e'
            }

            return level
        },

        mustacheString(string, model) {
            return Handlebars.compile(string || '')({ model })
        },
    },
}
</script>
<template>
    <div id="app"
      :class="`acadomia-env theme-transverse p-4 shadow-md rounded-lg ${landing ? landing.classname : ''}`"
      :style="CSSVars">
        <template v-if="landingLoading">
            <div class="flex items-center justify-center pt-20 pb-20">
                <BaseLoader style="width: 72px; height: 72px;" />
            </div>
        </template>
        <template v-else>
            <div class="lg:pb-0 pb-2.5 flex justify-between"
              :class="{invisible: currentScreen === 'success'}">
            </div>

            <div  class="flex justify-between items-center flex-wrap">
                <div class="block-type-wysiwyg" v-if="landing"
                v-html="mustacheString(landing.title, model)"></div>
                <div v-if="false" class="flex items-center flex-grow justify-end mb-5 lg:mb-0" >
                    <BaseButton
                      class="my-selection-button base-button-outline"
                      :class="{invisible: currentScreen === 'success'}"
                      :style="{'font-size': '16px', 'pointer-events': selectedCourses.length > 0 ? 'auto': 'none'}"
                      @click="currentScreen = 'basket'">
                        <span>Ma sélection</span>
                        <span
                          class="ml-2 w-8 h-8 text-white text-center inline-flex items-center justify-center rounded-full bg-black"
                          :class="{ 'pastille-active': selectedCourses.length > 0 }">
                            {{ selectedCourses.length }}
                        </span>
                    </BaseButton>
                </div>
            </div>

            <template v-if="currentScreen === 'home'">
                <HomeScreen v-if="landing"
                  :landing="landing"
                  :model="model"
                  :selectedCourses="selectedCourses"
                  :readableLevel="readableLevel"
                  :getDetailById="getDetailById"
                  :isStage="isStage"
                  :displayCoursesList="hasAnyPrefilledFilter || (clickedFindCourses && hasAnyFilter)"
                  :filteredLevels="filteredLevels"
                  :levelFilter="levelFilter"
                  :planningFilter="planningFilter"
                  :filteredSubjects="filteredSubjects"
                  :subjectsFilter="subjectsFilter"
                  :allPeriods="allPeriods"
                  :filteredPeriods="filteredPeriods"
                  :periodFilter="periodFilter"
                  :locationFilter="locationFilter"
                  :zipCodeFilter="zipCodeFilter"
                  :matchingCourses="groupedDisplayedCourses"
                  :matchingCoursesLength="matchingCoursesLength"
                  :coursesLoading="coursesLoading"
                  @levelChange="handleLevelChange"
                  @planningChange="handlePlanningChange"
                  @levelReset="levelFilter = null"
                  @planningReset="planningFilter = null"
                  @subjectsChange="handleSubjectChange"
                  @subjectsReset="subjectsFilter = []"
                  @periodsChange="handlePeriodChange"
                  @periodsReset="periodFilter = null"
                  @zipCodeFilterChange="handleZipcodeFilterChange"
                  @locationFilterChange="handleLocationFilterChange"
                  @locationFilterAdd="handleLocationFilterAdd"
                  @removeCourseClick="handleRemoveCourseClick"
                  @courseProgramClick="handleCourseProgramClick"
                  @selectCourseClick="handleSelectCourseClick"
                  @selectedVariantChange="handleSelectedVariantChange"
                  @bookCourse="handleBookCourse"
                  @bookClick="currentScreen = 'basket'"
                  @loadMore="handleLoadMore"
                  @findCourses="handleFindCourses"
                />
            </template>
            <template v-else-if="currentScreen === 'basket'">
                <BasketScreen
                  :landing="landing"
                  :model="model"
                  :selectedCourses="selectedCourses"
                  :getDetailById="getDetailById"
                  :isStage="planningFilter === 'Stages de vacances'"
                  :zipCodeFilter="zipCodeFilter"
                  @success="handleSuccess"
                  @modelChanged="handleModelChanged"
                  @removeCourseClick="handleRemoveCourseClick"
                  @courseProgramClick="handleCourseProgramClick"
                  @selectedVariantChange="handleSelectedVariantChange"
                  @addCourseClick="currentScreen = 'home'"
                />
            </template>
            <template v-else-if="currentScreen === 'success'">
                <div class="flex flex-col-reverse lg:space-x-16 flex-col lg:flex-row">
                    <div class="basis-full lg:basis-1/2">
                        <img :src="landing.success_image" alt="image">
                    </div>
                    <div class="basis-full lg:basis-1/2 mb-10" style="max-width: 600px">
                        <div class="block-type-wysiwyg"
                          v-html="mustacheString(landing.success_message, contactFormModel)">
                        </div>

                        <BaseButton class="base-button-outline mt-10"
                          @click="currentScreen = 'home'">
                            + Faire une nouvelle demande
                        </BaseButton>
                    </div>
                </div>
            </template>
            <div class="modal z-[10000] p-4 lg:p-16 bg-opacity-50 bg-black fixed inset-0 h-full w-full overflow-auto" v-if="modalOpen">
                <div class="lg:p-8 bg-cream">
                    <div class="flex justify-between">
                        <div></div>
                        <div class="flex items-center cursor-pointer py-2 px-4 lg:pb-5 lg:py-4 lg:px-5" @click="modalOpen = false">
                            <span class="invisible lg:visible underline-link font-bold">Revenir à la recherche</span>
                            <div class="lg:ml-5 ml-3 p-3 text-white text-center inline-flex items-center justify-center rounded-full bg-black">
                                <IconCross style="width:15px; height:15px;"/>
                            </div>
                        </div>
                    </div>
                    <CourseDetails
                      @bookCourse="handleBookCourse"
                      :details="modalProps.detail"
                      :group="modalProps.group"
                      :course="modalProps.course"
                      :isStage="isStage">
                    </CourseDetails>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="stylus">
@import '~aca-design/dist/aca-design.css'
@import 'assets/tailwind.css'

@css{
    #app {
        --background: var(--background-rgb, 238, 249, 251);
        background: rgb(var(--background));
    }

    .gradient-right {
        background: linear-gradient(90deg, rgba(var(--background), 0)  0%, rgba(var(--background), 1)  50%);
    }

    .gradient-left {
        background: linear-gradient(90deg, rgba(var(--background), 1) 50%, rgba(var(--background), 0) 100%);
    }
}

#app
    font-family "Montserrat"
    font-size 16px

.font-toronto-gothic
    font-family "toronto-gothic"

.stabilo
    display inline
    background var(--color1)

.bg-cream
    background var(--AkCream)

.bg-blue
    background #57C2DC

.bg-coral
    background var(--AkMainCoral)

.bg-yellow
    background var(--AkMainYellow)

.block-manuscrit
    color var(--AkBlueNote)
    font-weight 700
    font-size 24px
    line-height 30px

.pastille-active
    background var(--color3)

.base-button
    padding-left 1rem
    padding-right 1rem

.base-select .select-wrapper
    min-height 50px

.base-checkbox .input-wrapper
    box-sizing content-box

.my-selection-button.base-button
    background #FFF

.base-multi-select
    .select-wrapper
        min-height 50px
        align-items center

    .multiselect__tags
        padding-top 10px

    .multiselect__placeholder
        color #000

    .multiselect__content-wrapper
        margin-top 12px
        border 1px solid #e8e8e8
        border-radius 10px

.base-checkbox.no-label label
    display none

.tag
    background var(--AkMainCoral)
    border-radius 4px
    font-size 15px
    font-weight 700
    padding 6px 9px

.tag-ligne
    background var(--AkMainYellow)
    border-radius 4px
    font-size 15px
    font-weight 700
    padding 6px 9px

.underline-link
    font-style: normal;
    line-height: 20px;
    display inline-block
    border-bottom 3px solid currentColor
    padding-bottom 2px


.animated-underline-link
    font-weight 700
    padding-bottom 8px
    background-position 0 100%
    background-repeat no-repeat
    background-size 100% 3px
    transition background-size .5s ease-out .2s
    background-image linear-gradient(currentColor, currentColor)
    display inline-flex

    &:hover
        background-size 0 3px

.block-type-wysiwyg
    h3
        font-weight 600
        font-size 24px
        line-height 36px

.course-card.selected-label
    &:after
        content: "Sélectionné"
        padding 5px 10px
        background var(--AkBlack)
        color var(--AkWhite)
        position absolute
        top -10px
        right 40px
        font-weight 700
        font-size 15px
        z-index 1

.course-card.active
    background var(--color7)

.level, .note
    color var(--AkBlueNote)

.list-popin
    display list-item

.list-popin::marker
    color var(--AkMainGreen)
    font-size 1.5rem

.previous-btn
    width 50px
    height 50px
    & .previous-btn-inner
        width 17px

.zipcode
    font-size 16px !important

@media (max-width: 1024px)

    .course-card.selected-label:after
        top -1rem
        right 1.5rem

    .previous-btn
        width 40px
        height 40px
        & .previous-btn-inner
            width 15px


    .block-manuscrit
        font-size 14px
        line-height 24px

@media (max-width: 385px)
    .zipcode
        font-size 14px !important
</style>
