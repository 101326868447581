<script>
import { BaseButton, IconAddress, IconPhoneLight, IconCentre } from 'aca-design'
import axios from 'axios'
import BaseLoader from '@/components/BaseLoader.vue'

export default {
    name: 'AgencyAddress',

    props: {
        agencyId: {
            type: [Number, String],
            required: true,
        },
        showMap: {
            type: [Boolean],
            default: true,
        }
    },

    components: {
        BaseButton,
        IconAddress,
        IconPhoneLight,
        IconCentre,
        BaseLoader
    },

    watch: {
        agencyId: {
            handler: async function (agenceId) {
                this.loading = true
                this.$emit('loading')
                this.agency = await this.fetchAgency(agenceId)
                this.$emit('loaded')
                this.loading = false
            },


            immediate: true,
        },
    },

    data () {
        return {
            loading: true,
            agency: null
        }
    },

    computed: {
        query() {
            return [
                this.agency.agenceAddresse1,
                this.agency.agenceAddresse2,
                this.agency.agenceCpCode || this.agency.agenceCodePostal,
                this.agency.agenceVille,
            ].join('%20')
        }
    },

    methods: {
        numberWithSpaces(value, pattern) {
            let i = 0
            const phone = value.toString();
            return pattern.replace(/#/g, () => phone[i++]);
        },

        async fetchAgency(id){
            const options = {
                method: 'GET',
                url: `${process.env.VUE_APP_CONTACT_API_URL}/agence/chargerAgenceParId`,
                params: { agenceId: id },
            }
            const { data } = await axios.request(options)

            return data
        },
    },
}
</script>

<template>
    <div class="rounded-2xl p-5 lg:p-10" style="background: var(--colorMinor)">
        <div v-if="loading" class="flex items-center justify-center pt-20 pb-20">
            <BaseLoader class="w-16"/>
        </div>
        <template v-else>
            <div class="text-xl mb-6 font-semibold flex justify-between">
                <div class="font-bold text-2xl flex items-center">
                    <IconCentre class="shrink-0 mr-2" style="color: var(--AkMainBlue); width:40px;"/>
                    Où se déroulera le stage ?
                </div>
                <span>
                    <slot name="top-right"></slot>
                </span>
            </div>

            <span class="stabilo font-bold mb-2">
                Votre centre {{ agency.agenceLibelle }}
            </span>

            <div class="mb-2 flex align-top">
                <IconAddress class="mr-2 mt-1" style="align-self: flex-start;" />
                <a class="block" :href="`https://maps.google.com/maps?q=${query}`" target="_blank">{{ agency.agenceAddresse1 }} {{ agency.agenceAddresse2 }} <br>
                {{ agency.agenceCodePostal }} {{ agency.agenceVille }}</a>
            </div>
            <div class="mb-2 flex align-top">
                <IconPhoneLight class="mr-2 mt-1" style="width: 16px; align-self: flex-start;" />
                <span>
                    {{ numberWithSpaces(agency.agenceTelephone, '## ## ## ## ##') }}
                </span>
            </div>
            <div class="mb-4 ml-6 list-popin">
                Nos conseillers sont disponibles : <br>
                {{ agency.agenceHoraires }}
            </div>
            <div class="mb-4 flex justify-center lg:block">
                <BaseButton class="base-button-solid text-sm font-semibold "
                  @click="$emit('bookCourse')">
                    Choisir ce stage
                </BaseButton>
            </div>
            <template v-if="showMap">
                <iframe width="100%" height="200" class="mb-6" :src="`https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=${query}&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed`" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </template>
        </template>
    </div>
</template>

<style lang="stylus">
</style>